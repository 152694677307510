<template>
  <dashboard-layout>

    <template slot="heading">
      <v-toolbar-title class="d-none">
        License Transfers Offers
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-if="selected.length">
        <v-btn
          icon
          @click="accept"
        >
          <v-icon>mdi-check-bold</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="decline"
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </template>

    </template>

    <template slot="content">

      <v-alert type="error" v-if="error" rounded="0">{{ error }}</v-alert>

      <loading-circular v-if="loading"></loading-circular>

      <template v-if="!loading">

        <v-alert
          rounded="0"
          type="info"
          v-if="!allLicenseOffers.length && !error"
        >
          No offers yet.
        </v-alert>

        <v-list
          v-if="allLicenseOffers.length"
          three-line
          nav
        >
          <v-list-item-group
            multiple
            v-model="selected"
            active-class="primary--text"
          >
            <template v-for="(lt, i) in allLicenseOffers">
              <v-list-item :key="i" :value="lt">
                <template v-slot:default="{ active }">

                  <rand-list-item-avatar
                    :str="lt.product_key.code"
                    size="56"
                  >
                  </rand-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="lt.product_key.code"></v-list-item-title>

                    <template v-if="!lt.error">
                      <v-list-item-subtitle v-text="'Type: ' + lt.product_key.sku_str.toUpperCase()"></v-list-item-subtitle>
                      <v-list-item-subtitle>From: {{ lt.user.first_name }} {{ lt.user.last_name }} ({{ lt.user.email }})</v-list-item-subtitle>
                    </template>

                    <template v-if="lt.error">
                      <v-list-item-subtitle class="error--text" v-if="lt.error">
                        Unable to process license transfer.
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="error--text" v-if="lt.error">{{ lt.error }}</v-list-item-subtitle>
                    </template>

                  </v-list-item-content>

                  <v-list-item-action>

                    <v-icon v-if="!active" color="grey lighten-1">mdi-checkbox-blank-outline</v-icon>
                    <v-icon v-else color="primary">mdi-checkbox-marked</v-icon>

                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </template>
    </template>
  </dashboard-layout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'LicenseOffers',
    data() {
      return {
        loading: true,
        error: null,
        selected: []
      }
    },
    async created() {
      try {
        this.error = null
        await this.fetchLicenseOffers()
      } catch(e) {
        this.error = e
      } finally {
        this.loading = false
      }
    },
    methods: {
      async accept() {
        await this.acceptOrDecline(true)
        if (!this.withErrorsCount) {
          this.$toast('Licenses offers accepted successfully!')
          this.selected = []
          this.$router.push({name: 'LicenseKeys'})
        }
      },
      async decline() {
        var yn = await this.$confirm('Are you sure you want to decline?', {title: 'Decline?'})
        if (yn) {
          await this.acceptOrDecline(false)
          if (!this.withErrorsCount)
            this.selected = []
        }
      },
      async acceptOrDecline(accepted) {
        const ids = this.selected.map(lt => lt.id)
        await Promise.all(ids.map(id => this.patchLicenseOffer({ id, accepted })))
      },
      ...mapActions(['fetchLicenseOffers', 'patchLicenseOffer'])
    },
    computed: {
      withErrorsCount() {
        return this.allLicenseOffers.filter(lt => lt.error).length
      },
      ...mapGetters(['allLicenseOffers'])
    },
    watch: {
      selected() {
        this.allLicenseOffers.forEach(lt => {
          if (lt.error)
            this.$store.commit('UPDATE_LICENSE_OFFER', {id: lt.id, data: {error: null }})
        })
      }
    }
  }
</script>
